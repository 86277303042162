// 常量路由
export const constantRoutes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-s-home',
      name: '首页',
      affix: true
    },
    component: () => import('../views/home/index.vue')
  }
]

// 任意路由
export const anyRoutes = [
  //
]

// 动态路由
export const asyncRoutes = () => [
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/index.vue'),
    meta: { name: '订单管理', icon: 'el-icon-s-order' },
    children: [
      {
        path: '/order/order-list',
        name: 'OrderList',
        component: () => import('@/views/order/order-list/list.vue'),
        meta: { name: '订单列表', keepAlive: true }
      },
      {
        path: '/order/order-list2',
        name: 'OrderList2',
        component: () => import('@/views/order/order-list/list2.vue'),
        meta: { name: '订单列表（新）', keepAlive: true }
      },
      {
        path: '/order/detail/:id?',
        name: 'OrderDetail',
        component: () => import('@/views/order/order-list/detail.vue'),
        meta: { name: '详情', hidden: true, keepAlive: true, activeMenu: '/order/order-list' }
      },
      {
        path: '/order/delivery/:id?',
        name: 'OrderDelivery',
        component: () => import('@/views/order/order-list/delivery.vue'),
        meta: { name: '发货', hidden: true, activeMenu: '/order/order-list' }
      },
      {
        path: '/order/delivery_store/:id?',
        name: 'OrderDeliveryStore',
        component: () => import('@/views/order/order-list/delivery-store.vue'),
        meta: { name: '门店自提', hidden: true, activeMenu: '/order/order-list' }
      },
      {
        path: '/order/order-bill/:id?',
        name: 'OrderBill',
        component: () => import('@/views/order/order-list/bill.vue'),
        meta: { name: '账单', hidden: true, activeMenu: '/order/order-list' }
      },
      {
        path: '/order/order-list/update-goods/:id?',
        name: 'OrderUpdateGoods',
        component: () => import('@/views/order/order-list/modules/update-goods.vue'),
        meta: { name: '修改商品', hidden: true, activeMenu: '/order/order-list' }
      },
      {
        path: '/order/contract-detail/:id?',
        name: 'OrderContractDetail',
        component: () => import('@/views/order/order-list/contract-detail.vue'),
        meta: { name: '合同凭证', hidden: true, activeMenu: '/order/order-list' }
      },
      {
        path: '/order/credit-report/:id?',
        name: 'OrderCreditReport',
        component: () => import('@/views/order/order-list/credit-report.vue'),
        meta: { name: '征信报告', hidden: true, activeMenu: '/order/order-list' }
      },
      // {
      //   path: '/order/bill-list',
      //   name: 'BillList',
      //   component: () => import('@/views/order/bill-list/list.vue'),
      //   meta: { name: '账单列表', keepAlive: true }
      // }
    ]
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import('@/views/finance/index.vue'),
    meta: { name: '财务管理', icon: 'el-icon-coin' },
    children: [
      {
        path: '/finance/withdraw/list',
        name: 'WithdrawList',
        component: () => import('@/views/finance/withdraw/list.vue'),
        meta: { name: '资金管理' }
      },
      {
        path: '/finance/bill/list',
        name: 'BillList',
        component: () => import('@/views/finance/bill/list.vue'),
        meta: { name: '动账明细' }
      }
    ]
  },
  {
    path: '/system',
    name: 'System',
    component: () => import('@/views/system/index.vue'),
    meta: { name: '系统管理', icon: 'el-icon-setting' },
    children: [
      {
        path: '/capital/setting',
        name: 'CapitalSetting',
        component: () => import('@/views/system/capital/index.vue'),
        meta: { name: '资方设置' }
      }
    ]
  }
]
